import { Nullable } from 'src/types/@custom_types/helpers.t';
import {
  CLEAR_MANIFEST,
  CLEAR_MANIFEST_SERVICE_APPLETS,
  SET_MANIFEST_BACKLOGS,
  SET_MANIFEST_BACKLOGS_EVENTS,
  SET_MANIFEST_BACKLOGS_FILTERS,
  SET_MANIFEST_FILTERS,
  SET_MANIFEST_SERVICE_APPLETS,
} from '../actions/manifest';
import moment, { Moment } from 'moment';
import { FETCH_STATUS } from 'src/constants';
// import { sessionEventsListData } from 'src/__generated__/graphql';
import { SortableSessionsDataProps } from 'src/views/Manifest/Backlogs/SortableSessions/SortableSessions';
import { REHYDRATE } from 'redux-persist';

export type TManifestSyncBacklogsEventsSessionsValidations = Array<{
  non_session_id: string;
  event_code: string;
  original_event_code: string;
  status: 'for_hmi' | 'complete';
  original_session_id: string;
  session_id: string;
  date_validated: string;
}>;

export type TManifestSyncBacklogsEventsSessions = Array<{
  session_id: string;
  status: 'for_hmi' | 'complete';
  validations: TManifestSyncBacklogsEventsSessionsValidations;
}>;

// export type TManifestSyncBacklogsEventsPayload = {
//   sessions: TManifestSyncBacklogsEventsSessions;
// };

export enum MANIFEST_BACKLOGS_SYNC_STATUS {
  FOR_HMI = 'for_hmi',
  COMPLETE = 'complete',
}

export type TManifestSyncBacklogsEventsPayload = {
  sessions: Array<{
    session_id: Nullable<string>; //set to null
    app_code: string;
    start_timestamp: string;
    end_timestamp: string;
    compliance_list: Array<any>;
    site_tz: string; //need pa mahibaw.an asa kuhaon
    network_id: number;
    network_name: string;
    site_name: string;
    site_id: number;
    doc_id: string; //sender ang mag send - UUID
    critical_events: Array<any>;
    calc_status: MANIFEST_BACKLOGS_SYNC_STATUS;
    status: MANIFEST_BACKLOGS_SYNC_STATUS;
    validations: Array<{
      non_session_id: Nullable<string>; //set to null, if ang session_id sa taas kay dli null copy session_id, if null kay generate dayun copy sa session
      event_code: string;
      event_location_id: number; //need pa mahibaw.an asa kuhaon
      event_location_name: string; //need pa mahibaw.an asa kuhaon
      event_site_tz: string; //need pa mahibaw.an asa kuhaon
      event_site_timestamp_utc: string; //queue in
      event_network_id: number;
      event_network_name: string;
      event_asset: string; //need pa mahibaw.an asa kuhaon
      event_timestamp: string; //queue in
      event_start_timestamp: string; //queue in
      event_end_timestamp: string; //queue out
      original_event_code: string;
      original_session_id: string;
      session_id: string;
      status: MANIFEST_BACKLOGS_SYNC_STATUS;
      date_validated: string;
    }>;
  }>;
};

export type TManifestServiceApplets = {
  data: TManifestServiceAppletsData[];
  isLoading: boolean;
  error: Nullable<string>;
};

export type TManifestServiceAppletsData = {
  code: Nullable<string>;
  name: Nullable<string>;
  app_logo: Nullable<string>;
};

export type TManifestFilters = {
  results: number;
  dateFilter: boolean;
  startTime: Moment;
  endTime: Moment;
  pageCount: number;
  eventsPerPage: number;
  offset: number;
  currentPage: number;
  sortValue: string;
  search: string;
  sort_by: string;
  apps: string[];
  sessionTableFilters: {
    session_ids: string[];
    vehicle_ids: string[];
    order_numbers: string[];
    upcs: string[];
    product_numbers: string[];
    classifications: string[];
    status: string[];
    critical_events: string[];
    sites: string[];
    sort: 'DESC' | 'ASC';
    tags: string[];
  };
  eventTableFilters: {
    sites: (string | number)[];
    feeders: (string | number)[];
    locations: (string | number)[];
    locations_sites: string[];
    locations_floors: string[];
    status: (string | number)[];
    event_types: (string | number)[];
    events: (string | number)[];
    tags: (string | number)[];
  };
};

export type TManifestBacklogsFilters = {
  search: string;
  apps: Array<string>;
  sites: Array<number>;
  status: Array<string>;
  startTime: Moment;
  endTime: Moment;
  timezone: string;
  eventsPerPage: number;
  currentPage: number;
  offset: number;
  limit: number;
};

export type TManifestBacklogsEvents = {
  data: Nullable<Array<SortableSessionsDataProps>>;
  fetchStatus: FETCH_STATUS;
  error: Nullable<string>;
};

export type TManfiestBacklogs = {
  results: number;
  pageCount: number;
  isDraggingImage: boolean;
  showBanner: boolean;
  errorEvent: Array<any>;
  events: TManifestBacklogsEvents;
  filters: TManifestBacklogsFilters;
};

export type TManifest = {
  serviceApplets: TManifestServiceApplets;
  filters: TManifestFilters;
  backlogs: TManfiestBacklogs;
};

export const initialState: TManifest = {
  serviceApplets: {
    data: [],
    isLoading: false,
    error: null,
  },
  filters: {
    results: 0,
    dateFilter: false,
    startTime: moment().startOf('day'),
    endTime: moment(),
    pageCount: 1, // default to zero
    eventsPerPage: 100,
    offset: 0,
    currentPage: 0,
    sortValue: 'Most Recent',
    search: '',
    sort_by: 'site_timestamp_utc',
    apps: [],
    sessionTableFilters: {
      session_ids: [],
      vehicle_ids: [],
      order_numbers: [],
      upcs: [],
      product_numbers: [],
      classifications: [],
      status: [],
      critical_events: [],
      sites: [],
      sort: 'DESC',
      tags: [],
    },
    eventTableFilters: {
      sites: [],
      locations: [],
      locations_sites: [],
      locations_floors: [],
      feeders: [],
      status: [],
      event_types: [],
      events: [],
      tags: [],
    },
  },
  backlogs: {
    results: 0,
    pageCount: 1,
    isDraggingImage: false,
    showBanner: false,
    errorEvent: [],
    events: {
      data: null,
      fetchStatus: FETCH_STATUS.INTITIAL,
      error: null,
    },
    filters: {
      search: '',
      apps: [],
      startTime: moment().startOf('day'),
      endTime: moment(),
      timezone: '',
      sites: [],
      status: [],
      eventsPerPage: 100,
      currentPage: 0,
      offset: 0,
      limit: 0,
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE: {
      const incoming: Partial<TManifest> = action.payload?.manifest;
      if (incoming && !incoming.backlogs) {
        // If backlogs is missing, set it to the initial state
        return {
          ...incoming,
          backlogs: initialState.backlogs,
        } as TManifest;
      }
      return (incoming || state) as TManifest;
    }
    case SET_MANIFEST_SERVICE_APPLETS: {
      return { ...state, serviceApplets: { ...state.serviceApplets, ...action.data } as TManifestServiceApplets };
    }
    case SET_MANIFEST_FILTERS: {
      return { ...state, filters: { ...state.filters, ...action.data } as TManifestFilters };
    }
    case SET_MANIFEST_BACKLOGS: {
      return { ...state, backlogs: { ...state.backlogs, ...action.data } as TManfiestBacklogs };
    }
    case SET_MANIFEST_BACKLOGS_FILTERS: {
      return {
        ...state,
        backlogs: {
          ...state.backlogs,
          filters: {
            ...state.backlogs.filters,
            ...action.data,
          },
        } as TManfiestBacklogs,
      };
    }
    case SET_MANIFEST_BACKLOGS_EVENTS: {
      return {
        ...state,
        backlogs: {
          ...state.backlogs,
          events: {
            ...state.backlogs.events,
            ...action.data,
          },
        } as TManfiestBacklogs,
      };
    }
    case CLEAR_MANIFEST_SERVICE_APPLETS: {
      return {
        ...state,
        serviceApplets: {
          data: [],
          isLoading: false,
          error: null,
        },
      };
    }
    case CLEAR_MANIFEST: {
      return initialState;
    }
    case CLEAR_MANIFEST_SERVICE_APPLETS: {
      return { ...state, serviceApplets: initialState.serviceApplets };
    }
    default:
      return state;
  }
};
