import axios from 'axios';
import * as config from '../config';

const floorplanImages = [
  'https://www.roomsketcher.com/wp-content/uploads/2016/10/Bedroom-Floor-Plans.jpg',
  'https://theeastonapartments.com/wp-content/uploads/EastonFloorPlansF2_B1_SFW.jpg',
  'https://media.apts247.info/13/13ff965224fa478a991e2d8de23b675b/floorplans/1b1b_500_332111.png',
  'https://collegeapartmentsource.com/wp-content/uploads/2018/04/Starlite-2-1.5-865sf.jpg',
  'https://mainstreetbellevue.com/assets/images/cache/detail_B3.1-d49d6c47bd2a5ca55e17cacefbdddcf2.jpg',
];

const sampleFloors = Array.apply(null, {length: 5}).map((_, i) => {
  return {
    id: i + 1,
    name: `Floor ${i + 1}`,
    order: i,
    capacity: 500,
    current: Math.floor((Math.random() * 1000) + 1),
    site_id: 227,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque congue hendrerit mollis.',
    file: floorplanImages[i % 5]
  }
});

export const SETISLOADINGFLOORS = 'floors/SETISLOADINGFLOORS';
export const SETFLOORS = 'floors/SETFLOORS';

export const setSampleFloors = (onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {
    dispatch({type: SETFLOORS, floors: sampleFloors});
  }
}

export const getFloors = (onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    dispatch({type: SETISLOADINGFLOORS, isLoadingFloors: true});

    axios(`${config.endpoints.base_url}floorplans`).then((resp) => {
      dispatch({type: SETFLOORS, floors: transformFloors(resp.data.data.rows)});
      dispatch({type: SETISLOADINGFLOORS, isLoadingFloors: false});
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    })
  }
}

export const getFloorsByServiceApplet = (serviceapplet = '', onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    dispatch({type: SETISLOADINGFLOORS, isLoadingFloors: true});

    axios(`${config.endpoints.base_url}floorplans${serviceapplet ? `?usecase=${serviceapplet}` : ''}`).then((resp) => {
      dispatch({type: SETFLOORS, floors: transformFloors(resp.data.data.rows)});
      dispatch({type: SETISLOADINGFLOORS, isLoadingFloors: false});
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    })
  }
}

const transformFloors = (floors) => {
  return floors.map(floor => {
    return {
      ...floor
    }
  })
}

export const getFloorByID = (floor_id) => {
  return (dispatch, getState) => {
    if(getState().floors.floors) {
      const floor = getState().floors.floors.find((floor) => {
        return floor.id === parseInt(floor_id, 10);
      });
      if(floor) {
        return floor;
      }
    }
    return null;
  };
}

export const getFloorByLocationId = (loc_id) => {
  return (dispatch, getState) => {
    const floor = getState().floors.floors.find((floor) => {
      return floor.locations?.find((l) => l.id === loc_id)
    });
    return floor;
  };
}

export const getFloorsBySiteID = (site_id) => {
  return (dispatch, getState) => {
    if(getState().floors.floors) {
      const floors = getState().floors.floors.filter((floor) => {
        return floor.site_id === parseInt(site_id, 10);
      });
      if(floors) {
        return floors;
      }
    }
    return null;
  };
}

export const SETISADDINGFLOOR = 'floors/SETISADDINGFLOOR';

export const addFloor = ({ floor, floorplanFile }, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch, getState) => {

    dispatch({type: SETISADDINGFLOOR, isAddingFloor: true});

    if(floorplanFile) {
      const formData = new FormData();

      formData.append('files', floorplanFile);
      const fileRequest = await axios.post(
        `${config.endpoints.base_url}assets`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );

      floor.asset = fileRequest.data.data[0].url;
    }

    const retransformedFloor = retransformFloor(floor);

    axios.post(
      `${config.endpoints.base_url}floorplans`,
      retransformedFloor
    ).then((resp) => {
      dispatch({type: SETISADDINGFLOOR, isAddingFloor: false});
      onSuccess(resp);
    }).catch((err) => {
      onError(err);
    })

  }
}

export const SETISEDITINGFLOOR = 'floors/SETISEDITINGFLOOR';

export const editFloor = ({ floorID, floor, floorplanFile }, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch, getState) => {

    dispatch({type: SETISEDITINGFLOOR, isEditingFloor: true});

    if(floorplanFile) {
      const formData = new FormData();

      formData.append('files', floorplanFile);
      const fileRequest = await axios.post(
        `${config.endpoints.base_url}assets`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );

      floor.asset = fileRequest.data.data[0].url;
    }

    const retransformedFloor = retransformFloor(floor);

    axios.put(
      `${config.endpoints.base_url}floorplans/${floorID}`,
      retransformedFloor
    ).then((resp) => {
      dispatch({type: SETISEDITINGFLOOR, isEditingFloor: false});
      onSuccess(resp);
    }).catch((err) => {
      onError(err);
    })

  }
}

const retransformFloor = (floor) => {
  return {
    name: floor.name,
    site_id: parseInt(floor.site_id, 10),
    capacity: parseInt(floor.capacity, 10),
    description: floor.description,
    asset: floor.asset,
    features: floor.features
  }
}

export const SETISREMOVINGFLOOR = 'sites/SETISREMOVINGFLOOR';

export const removeFloor = ({ floorID }, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    dispatch({type: SETISREMOVINGFLOOR, isRemovingFloor: true});
    axios.delete(
      `${config.endpoints.base_url}floorplans/${floorID}`
    ).then((resp) => {
      dispatch({type: SETISREMOVINGFLOOR, isRemovingFloor: false});
      onSuccess(resp);
    }).catch((err) => {
      onError(err);
    })

  }
}

export const updateFloorplanRequestConfig = ({ floorplanID, floorplanConfig }) => {
  let floorplan = {...floorplanConfig};
  delete floorplan.id;

  return axios.put(
    `${config.endpoints.base_url}floorplans/${floorplanID}`,
    floorplan
  );
};

export const getFloorplanDestinations = (floorplanID) => {
  return axios.get(
    `${config.endpoints.base_url}floorplans/${floorplanID}/destinations`
  );
};

export const addFloorplanDestination = (floorplanID, destination) => {
  return axios.post(
    `${config.endpoints.base_url}floorplans/${floorplanID}/destinations`,
      destination
  );
};

export const updateFloorplanDestination = (floorplanID, destination) => {
  return axios.put(
    `${config.endpoints.base_url}floorplans/${floorplanID}/destinations`,
      destination
  );
};

export const getDestinationTypes = () => {
  return axios.get(
    `${config.endpoints.base_url}types?table_from=destinations`
  );
};

export const getFloorplanEntities = (floorplanID) => {
  return axios.get(
    `${config.endpoints.base_url}floorplans/${floorplanID}/entities`
  );
};

export const addFloorplanEntity = (floorplanID, entity) => {
  return axios.post(
    `${config.endpoints.base_url}floorplans/${floorplanID}/entities`,
    entity
  );
};

export const updateFloorplanEntity = (floorplanID, entity) => {
  return axios.put(
    `${config.endpoints.base_url}floorplans/${floorplanID}/entities/${entity.id}`,
    entity
  );
};
