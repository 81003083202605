import { SETISLOADINGSHELVES, SETSHELVES, SETISADDINGSHELF, SETISEDITINGSHELF, SETISREMOVINGSHELF } from '../actions/shelves';

const initialState = {
  isLoadingShelves: false,
  shelves: [],
  isAddingShelf: false,
  isEditingShelf: false,
  isRemovingShelf: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SETISLOADINGSHELVES:
      return {
        ...state,
        isLoadingShelves: action.isLoadingShelves
      }
    case SETSHELVES:
      return {
        ...state,
        shelves: action.shelves
      }
    case SETISADDINGSHELF:
      return {
        ...state,
        isAddingShelf: action.isAddingShelf
      }
    case SETISEDITINGSHELF:
      return {
        ...state,
        isEditingShelf: action.isEditingShelf
      }
    case SETISREMOVINGSHELF:
      return {
        ...state,
        isRemovingShelf: action.isRemovingShelf
      }
    default:
      return state;
  }
}
