import { UPDATE_MANIFESTJUMPSESSIONFILTER, UPDATE_MANIFESTJUMPSESSIONEXPANDED, FILTER } from '../actions/events';

const initialState = {
  retainedMSessionsFilters: {},
  jumpSessionFilter: FILTER.SAMEASSESSIONSTABLE,
  sessionFilterExpanded: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MANIFESTJUMPSESSIONEXPANDED:
      return {
        ...state,
        sessionFilterExpanded: action.sessionFilterExpanded
      }
      break;
    case UPDATE_MANIFESTJUMPSESSIONFILTER:
      return {
        ...state,
        jumpSessionFilter: action.jumpSessionFilter
      }
      break;
    default:
      return state;
  }
}
