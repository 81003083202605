import moment from 'moment';
import {
  SET_FILTER,
  RESET,
  SET_DATE_FILTER,
  SET_SITE_TREE,
  SET_SELECTED_SITE_ID,
  SET_SELECTED_SITE, SET_SITES,
  SET_SELECTED_SITES,
  SET_SELECTED_LOCATIONS,
} from '../actions/dashboard';

const initialState = {
  filters: {
    locations: [],
    sites: [],
    devices: [],
    dateFrom: moment().subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss'),
    dateTo: moment().format('YYYY-MM-DD HH:mm:ss'),
    dateLabel: 'Last 30 Days',
    ready: false,
  },
  dateFilters: {
    startDate: moment().subtract(30, 'days'),
    endDate: moment(),
    dateLabel: 'Last 30 Days',
    timePeriodSelected: 'last30Days',
  },
  locationFilters: {
    siteTree: [],
    selectedSiteId: null,
    selectedSite: null,
    selectedSites: [],
    selectedLocations: [],
    selectedDevices: [],
  },
  sites: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER:
      return { ...state, filters: action.filters };
    case SET_DATE_FILTER:
      return { ...state, dateFilters: action.dateFilters };
    case SET_SITE_TREE:
      return {
        ...state,
        locationFilters: {
          ...state.locationFilters,
          siteTree: action.siteTree
        }
      };
    case SET_SELECTED_SITE:
      return {
        ...state,
        locationFilters: {
          ...state.locationFilters,
          selectedSite: action.selectedSite
        }
      };
    case SET_SELECTED_SITE_ID:
      return {
        ...state,
        locationFilters: {
          ...state.locationFilters,
          selectedSiteId: action.selectedSiteId
        }
      };
    case SET_SELECTED_SITES:
      return {
        ...state,
        locationFilters: {
          ...state.locationFilters,
          selectedSites: action.selectedSites
        }
      };
    case SET_SELECTED_LOCATIONS:
      return {
        ...state,
        locationFilters: {
          ...state.locationFilters,
          selectedLocations: action.selectedLocations
        }
      };
    case SET_SITES:
      return { ...state, sites: action.sites };
    case RESET:
      return initialState;
    default:
      return state;
  }
};
