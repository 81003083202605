import { SETISLOADINGFEEDERS, SETISADDINGFEEDERS, SETFEEDERS, SETISUPDATINGFEEDER } from '../actions/edgelessfeeders';

const initialState = {
  isLoadingFeeders: false,
  isAddingFeeders: false,
  isUpdatingFeeder: false,
  feeders: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SETISLOADINGFEEDERS:
      return {
        ...state,
        isLoadingFeeders: action.isLoadingFeeders
      }
    case SETFEEDERS:
      return {
        ...state,
        feeders: action.feeders
      }
    case SETISADDINGFEEDERS:
      return {
        ...state,
        isAddingFeeders: action.isAddingFeeders
      }
    case SETISUPDATINGFEEDER:
      return {
        ...state,
        isUpdatingFeeder: action.isUpdatingFeeder
      }
    default:
      return state;
  }
}
