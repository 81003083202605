export const SET_FILTER = 'dashboard/SET_FILTER';
export const SET_DATE_FILTER = 'dashboard/SET_DATE_FILTER';
export const RESET = 'dashboard/RESET';
export const SET_SITE_TREE = 'dashboard/SET_SITE_TREE';
export const SET_SELECTED_SITE_ID = 'dashboard/SET_SELECTED_SITE_ID';
export const SET_SELECTED_SITE = 'dashboard/SET_SELECTED_SITE';
export const SET_SITES = 'dashboard/SET_SITES';
export const SET_SELECTED_SITES = 'dashboard/SET_SELECTED_SITES';
export const SET_SELECTED_LOCATIONS = 'dashboard/SET_SELECTED_LOCATIONS';

export const setFilter = (filters) => {
  return (dispatch) => {
    dispatch({ type: SET_FILTER, filters });
  };
};

export const setDateFilter = (dateFilters) => {
  return (dispatch) => {
    dispatch({ type: SET_DATE_FILTER, dateFilters });
  };
};

export const reset = () => {
  return (dispatch) => {
    dispatch({ type: RESET });
  };
};

export const setSiteTree = (siteTree) => {
  return (dispatch) => {
    dispatch({ type: SET_SITE_TREE, siteTree });
  };
};

export const setSelectedSiteId = (selectedSiteId) => {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_SITE_ID, selectedSiteId });
  };
};

export const setSelectedSite = (selectedSite) => {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_SITE, selectedSite });
  };
};

export const setSites = (sites) => {
  return (dispatch) => {
    dispatch({ type: SET_SITES, sites });
  };
};

export const setSelectedSites = (selectedSites) => {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_SITES, selectedSites });
  };
};

export const setSelectedLocations = (selectedLocations) => {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_LOCATIONS, selectedLocations });
  };
};
