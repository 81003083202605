import axios from 'axios';
import * as config from '../config';

export const SETISLOADINGALERTS = 'alerts/SETISLOADINGALERTS';
export const SETALERTS = 'alerts/SETALERTS';

export const getAlerts = (onSuccess, onError) => {
    return (dispatch) => {
        // dispatch({ type: SETISLOADINGALERTS, isLoadingDevices: true });
        axios.get(`${config.endpoints.base_url}alerts`).then((res) => {
            dispatch({ type: SETALERTS, alerts: res.data.data });
            // dispatch({ type: SETISLOADINGALERTS, isLoadingDevices: false });
            onSuccess(res.data);
        }).catch((err) => {
            onError(err);
        })
    }
}

export const addAlert = (body, onSuccess, onError) => {
    axios.post(`${config.endpoints.base_url}alerts`, body).then((res) => {
        return onSuccess(res.data);
    }).catch((err) => {
        return onError(err);
    })
}

export const updateAlert = (body, id, onSuccess, onError) => {
    return (dispatch) => {
        axios.put(`${config.endpoints.base_url}alerts/${id}`, body, { timeout: 10000 }).then((res) => {
            return onSuccess(res)
        }).catch((err) => {
            return onError(err);
        })
    }
}

export const deleteAlert = (id, onSuccess, onError) => {
    return (dispatch) => {
        axios.delete(`${config.endpoints.base_url}alerts/${id}`, { timeout: 10000 }).then((res) => {
            return onSuccess(res)
        }).catch((err) => {
            return onError(err);
        })
    }
}

export const getAlertDetailsByID = (id, onSuccess, onError) => {
    return (dispatch) => {
        axios(`${config.endpoints.base_url}alerts/${id}`)
            .then((res) => onSuccess(res.data.data))
            .catch((err) => onError(err))
    }
}

export const getEventLogs = (id, onSuccess, onError) => {
    return (dispatch) => {
        axios(`${config.endpoints.base_url}alerts/${id}/eventslogs?order_by=incident_date&sort=DESC`)
            .then((res) => { onSuccess(res.data.data) })
            .catch((err) => onError(err))
    }
}

export const checkeDuplicate = (payload) => {
 
    return axios.get(`${config.endpoints.base_url}alerts/check-duplicate`,{params:payload})
            .then((res) => { return res })
            .catch((err) => {return err})
    
}

export const getAlertApps = () => {
    return axios(`${config.endpoints.base_url}/alerts/apps`);
}