import { SETISLOADINGFLOORS, SETFLOORS, SETISADDINGFLOOR, SETISEDITINGFLOOR, SETISREMOVINGFLOOR } from '../actions/floors';

const initialState = {
  isLoadingFloors: false,
  floors: [],
  isAddingFloor: false,
  isEditingFloor: false,
  isRemovingFloor: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SETISLOADINGFLOORS:
      return {
        ...state,
        isLoadingFloors: action.isLoadingFloors
      }
    case SETFLOORS:
      return {
        ...state,
        floors: action.floors
      }
    case SETISADDINGFLOOR:
      return {
        ...state,
        isAddingFloor: action.isAddingFloor
      }
    case SETISEDITINGFLOOR:
      return {
        ...state,
        isEditingFloor: action.isEditingFloor
      }
    case SETISREMOVINGFLOOR:
      return {
        ...state,
        isRemovingFloor: action.isRemovingFloor
      }
    default:
      return state;
  }
}
