import axios from 'axios';
import * as config from '../config';

const sampleShelves = Array.apply(null, {length: 5}).map((_, i) => {
  return {
    id: i + 1,
    name: `Shelf ${i + 1}`,
    planogram_id: i,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque congue hendrerit mollis.'
  }
});

export const SETISLOADINGSHELVES = 'shelves/SETISLOADINGSHELVES';
export const SETSHELVES = 'shelves/SETSHELVES';

export const setSampleShelves = (onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {
    dispatch({type: SETSHELVES, shelves: sampleShelves});
  }
}

export const getShelves = (onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    dispatch({type: SETISLOADINGSHELVES, isLoadingShelves: true});

    axios(`${config.endpoints.base_url}shelves`).then((resp) => {
      dispatch({type: SETSHELVES, shelves: transformShelves(resp.data.data.rows)});
      dispatch({type: SETISLOADINGSHELVES, isLoadingShelves: false});
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    })
  }
}

const transformShelves = (shelves) => {
  return shelves.map(shelf => {
    return {
      ...shelf
    }
  })
}

export const getShelfByID = (shelf_id) => {
  return (dispatch, getState) => {
    if(getState().shelves.shelves) {
      const shelf = getState().shelves.shelves.find((shelf) => {
        return shelf.id === parseInt(shelf_id, 10);
      });
      if(shelf) {
        return shelf;
      }
    }
    return null;
  };
}

export const getShelvesBySiteID = (site_id) => {
  return (dispatch, getState) => {
    if(getState().shelves.shelves) {
      const shelves = getState().shelves.shelves.filter((shelf) => {
        return shelf.site_id === parseInt(site_id, 10);
      });
      if(shelves) {
        return shelves;
      }
    }
    return null;
  };
}

export const SETISADDINGSHELF = 'shelves/SETISADDINGSHELF';

export const addShelf = ({ shelf }, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch, getState) => {

    dispatch({type: SETISADDINGSHELF, isAddingShelf: true});
    
    const retransformedShelf = retransformShelf(shelf);

    axios.post(
      `${config.endpoints.base_url}shelves`,
      retransformedShelf
    ).then((resp) => {
      dispatch({type: SETISADDINGSHELF, isAddingShelf: false});
      onSuccess(resp);
    }).catch((err) => {
      onError(err);
    })

  }
}

export const SETISEDITINGSHELF = 'shelves/SETISEDITINGSHELF';

export const editShelf = ({ shelfID, shelf }, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch, getState) => {

    dispatch({type: SETISEDITINGSHELF, isEditingShelf: true});

    const retransformedShelf = retransformShelf(shelf);

    axios.put(
      `${config.endpoints.base_url}shelves/${shelfID}`,
      retransformedShelf
    ).then((resp) => {
      dispatch({type: SETISEDITINGSHELF, isEditingShelf: false});
      onSuccess(resp);
    }).catch((err) => {
      onError(err);
    })

  }
}

const retransformShelf = (shelf) => {
  return {
    ...shelf
  }
}

export const SETISREMOVINGSHELF = 'sites/SETISREMOVINGSHELF';

export const removeShelf = ({ shelfID }, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    dispatch({type: SETISREMOVINGSHELF, isRemovingShelf: true});
    axios.delete(
      `${config.endpoints.base_url}shelves/${shelfID}`,
      {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        }
      }
    ).then((resp) => {
      dispatch({type: SETISREMOVINGSHELF, isRemovingShelf: false});
      onSuccess(resp);
    }).catch((err) => {
      onError(err);
    })

  }
}

export const saveShelfFeeders = ({shelfID, feeders}) => {
  return axios.post(
    `${config.endpoints.base_url}shelves/${shelfID}/feeders`,
    { feeders }
  );
};

export const editShelfRequest = ({shelfID, shelf}) => {
  const retransformedShelf = retransformShelf(shelf);
  return axios.put(
    `${config.endpoints.base_url}shelves/${shelfID}`,
    retransformedShelf
  );
};

export const duplicateShelf = ( { id }, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch, getState) => {

    axios.post(
      `${config.endpoints.base_url}shelves/${id}/duplicate`,
      {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        }
      }
    ).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const switchShelfPlanogramRequest = (shelfID, planogramID) => {
  const body = {
    planogram_id: planogramID
  };
  return axios.post(
    `${config.endpoints.base_url}shelves/${shelfID}/switch`,
    body
  );
};