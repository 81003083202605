import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import alerts from './alerts';
import auth from './auth';
import insights from './insights';
import networks from './networks';
import sites from './sites';
import floors from './floors';
import locations from './locations';
import devices from './devices';
import feeders from './feeders';
import edgelessfeeders from './edgelessfeeders';
import tags from './tags';
import blender from './blender';
import devicetype from './devicetype';
import scenes from './scenes';
import dashboard from './dashboard';
import useCases from './useCases';
import planograms from './planograms';
import shelves from './shelves';
import timezones from './timezones';
import settingsPersist from './settingsPersist';
import events from './events';
import manifest from './manifest';
import toastr from './toastr';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  alerts,
  auth,
  insights,
  networks,
  sites,
  floors,
  locations,
  devices,
  feeders,
  edgelessfeeders,
  tags,
  blender,
  devicetype,
  // window,
  scenes,
  dashboard,
  useCases,
  planograms,
  shelves,
  timezones,
  settingsPersist,
  events,
  manifest,
  toastr
});

export default createRootReducer;
