import { SETISLOADINGPLANOGRAMS, SETPLANOGRAMS } from '../actions/planograms';

const initialState = {
  isLoadingPlanograms: false,
  planograms: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SETISLOADINGPLANOGRAMS:
      return {
        ...state,
        isLoadingPlanograms: action.isLoadingPlanograms
      };
    case SETPLANOGRAMS:
      return {
        ...state,
        planograms: action.planograms
      };
    default:
      return state;
  }
};
