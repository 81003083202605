import jwt_decode from 'jwt-decode';
import axios from 'axios';
import qs from 'qs';
import * as config from '../config';

export const getID = () => {
  const token = localStorage.getItem('access_token');
  try {
    return jwt_decode(token).sub;
  }
  catch (e) {
    window.location.reload();
  }
};

export const getUserName = () => {
  const token = localStorage.getItem('access_token');
  try {
    return jwt_decode(token).preferred_username;
  }
  catch (e) {
    window.location.reload();
  }
};

export const getGivenName = () => {
  const token = localStorage.getItem('access_token');
  try {
    return jwt_decode(token).given_name;
  }
  catch (e) {
    window.location.reload();
  }
};

export const getFamilyName = () => {
  const token = localStorage.getItem('access_token');
  try {
    return jwt_decode(token).family_name;
  }
  catch (e) {
    window.location.reload();
  }
}

export const getEmail = () => {
  const token = localStorage.getItem('access_token');
  try {
    return jwt_decode(token).email;
  }
  catch (e) {
    window.location.reload();
  }
};

export const getNetworkName = () => {
  const token = localStorage.getItem('access_token');
  try {
    return jwt_decode(token).networks;
  }
  catch (e) {
    window.location.reload();
  }
};

export const getTenant = () => {
  const token = localStorage.getItem('access_token');
  try {
    return jwt_decode(token).tenant;
  }
  catch (e) {
    window.location.reload();
  }
};

export const getUserRoles = () => {
  const token = localStorage.getItem('access_token');
  try {
    return jwt_decode(token).resource_access;
  }
  catch (e) {
    window.location.reload();
  }
};

export const hasRole = (role) => {
  return (dispatch, getState) => {

    if (!role) {
      return true;
    }

    const permissions = getState().auth.permissions;
    let hasRoleFlag = false;

    permissions.forEach((permission) => {
      if (permission.rsname === role.rsname) {
        if (permission.scopes.indexOf(role.scope) !== -1) {
          hasRoleFlag = true;
        }
      }
    });

    return hasRoleFlag;
  }
};

export const getUserSubscription = () => {
  const token = localStorage.getItem('access_token');
  try {
    return jwt_decode(token).apps;
  }
  catch (e) {
    window.location.reload();
  }
}

export const SETUSER = 'user/SETUSER';

export const getUser = ({ id }, onSuccess = () => { }, onError = () => { }) => {
  return async (dispatch) => {
    axios(
      `${config.endpoints.base_url}admin/users/${id}`
    ).then((resp) => {
      dispatch({ type: SETUSER, user: resp.data.data });
      return onSuccess(resp);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const SETUSERPERMISSIONS = 'user/SETUSERPERMISSIONS';

export const getUserPermissions = (onSuccess = () => { }, onError = () => { }) => {
  return async (dispatch) => {

    const data = qs.stringify({
      'grant_type': 'urn:ietf:params:oauth:grant-type:uma-ticket',
      'audience': 'viana-services'
    });

    axios.post(
      `${config.auth.access_token_url}`,
      data
    ).then((resp) => {
      let permissions = null
      try {
        permissions = jwt_decode(resp.data?.access_token)?.authorization?.permissions;
      }
      catch (e) {
        window.location.reload();
      }
      if (permissions) {
        dispatch({ type: SETUSERPERMISSIONS, permissions });
      }
      return onSuccess(resp);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const SETISEDITINGUSER = 'user/SETISEDITINGUSER';

export const editUser = ({ id, user, file }, onSuccess = () => { }, onError = () => { }) => {
  return async (dispatch) => {

    dispatch({ type: SETISEDITINGUSER, isEditingUser: true });

    if (file) {
      const formData = new FormData();
      formData.append('files', file);
      const fileRequest = await axios.post(
        `${config.endpoints.base_url}assets`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );
      user.image = fileRequest.data.data[0].url;
    }

    axios.put(
      `${config.endpoints.base_url}v2/admin/users/${id}`,
      user
    ).then(() => {
      dispatch({ type: SETISEDITINGUSER, isEditingUser: false });
      return onSuccess();
    }).catch((err) => {
      onError(err);
    });
  };
};

export const LOGOUT = 'user/LOGOUT';

export const logoutUser = () => {
  return (dispatch) => {
    dispatch({ type: LOGOUT });
  };
};

export const SETUSERS = 'user/SETUSERS';
export const SETISLOADINGUSERS = 'user/SETISLOADINGUSERS'

export const getUsers = (onSuccess = () => { }, onError = () => { }) => {
  return async (dispatch) => {
    dispatch({ type: SETISLOADINGUSERS, isLoadingUsers: true });
    axios.get(
      `${config.endpoints.base_url}admin/users`
    ).then((resp) => {
      dispatch({ type: SETUSERS, users: resp.data.data.rows });
      dispatch({ type: SETISLOADINGUSERS, isLoadingUsers: false });
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const getUserByKeycloakID = (id) => {
  return axios.get(
    `${config.endpoints.base_url}/admin/users/${id}`
  );

};

export const getSasToken = () =>{
  return axios.post(
    `${config.endpoints.base_url}/auth/sas-token`
  );
}