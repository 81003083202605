import axios from 'axios';
import moment from 'moment';
import * as config from '../config';

export const SETISLOADINGDEVICES = 'devices/SETISLOADINGDEVICES';
export const SETDEVICES = 'devices/SETDEVICES';

export const getDevices = (onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    dispatch({type: SETISLOADINGDEVICES, isLoadingDevices: true});

    axios(`${config.endpoints.base_url}v4/devices`).then((resp) => {
      dispatch({type: SETISLOADINGDEVICES, isLoadingDevices: false});
      dispatch({type: SETDEVICES, devices: resp.data.data.rows});
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    })
  }
}

export const getDeviceByID = (device_id) => {
  return (dispatch, getState) => {
    if(getState().devices.devices) {
      const device = getState().devices.devices.find((device) => {
        return device.id === parseInt(device_id, 10);
      });
      if(device) {
        return device;
      }
    }
    return null;
  };
}

export const SETISEDITINGDEVICE = 'sites/SETISEDITINGDEVICE';

export const editDevice = ({ id, device }, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {

    const retransformedDevice = retransformDevice(device);
    dispatch({type: SETISEDITINGDEVICE, isEditingDevice: true});
    axios.put(
      `${config.endpoints.base_url}v2/devices/${id}`,
      retransformedDevice,
    ).then((resp) => {
      dispatch({type: SETISEDITINGDEVICE, isEditingDevice: false});
      return onSuccess();
    }).catch((err) => {
      dispatch({type: SETISEDITINGDEVICE, isEditingDevice: false});
      onError(err);
    })

  }
}

const retransformDevice = (device) => {
  if(device.feeders.length > 0) {
    device.feeders = device.feeders.map(row => {
      return {
        id: row.id,
        location_id: row.location_id
      }
    })
  }
  return {
    name: device.name,
    site_id: device.site_id,
    location_id: device.location_id,
    description: device.description,
    // zone_id: device.zone_id,
    type_id: device.type_id,
    tags: device.tags,
    feeders: device.feeders
    // device_attributes: [
    //   {attribute: 'description', value: device.description},
    //   {attribute: "os", value: "Windows 10"},
    // 	{attribute: "processor", value: "Intel&reg; Core&trade; i7-97000K"},
    // 	{attribute: "viana_version", value: "4.62.7"},
    // 	{attribute: "serial_number", value: "48eec7f3-4353-4759-93ee-88aa7808455c"}
    // ]
  }
}

export const STATUS = {
  ONLINE: { name: 'Online', from: 0, to: 1 },
  WARNING: { name: 'Warning', from: 1, to: 6 },
  OFFLINE: { name: 'Offline', from: 6, to: Number.POSITIVE_INFINITY },
  INACTIVE: { name: 'Inactive', from: -1 },
}

export const getDeviceStatus = (updatedAt) => {
  let status = STATUS.INACTIVE;
  let duration = null;

  if(updatedAt) {
    duration = moment.duration(moment().diff(updatedAt));
    const hours = duration.asHours();

    if (hours >= STATUS.ONLINE.from && hours < STATUS.ONLINE.to) {
      status = STATUS.ONLINE;
    } else if (hours >= STATUS.WARNING.from && hours < STATUS.WARNING.to) {
      status = STATUS.WARNING;
    } else if (hours >= STATUS.OFFLINE.from && hours < STATUS.OFFLINE.to) {
      status = STATUS.OFFLINE;
    }
  }

  return status;

}

export const CONNECTIVITYSTATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};

export const getDevicesBySiteID = (site_id) => {
  return (dispatch, getState) => {
    if(getState().devices.devices) {
      const devices = getState().devices.devices.filter((device) => {
        return device.site_id === parseInt(site_id, 10);
      });
      if(devices) {
        return devices;
      }
    }
    return null;
  };
}

export const getDevicesByLocationID = (location_id) => {
  return (dispatch, getState) => {
    if(getState().devices.devices) {
      const devices = getState().devices.devices.filter((device) => {
        return device.location_id === parseInt(location_id, 10);
      });
      if(devices) {
        return devices;
      }
    }
    return null;
  };
}

export const deviceActivation = (id,  onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {
    dispatch({type: SETISEDITINGDEVICE, isEditingDevice: true});
    axios.post(
      `${config.endpoints.base_url}v1/devices/${id}/activation`
    ).then((resp) => {
      dispatch({type: SETISEDITINGDEVICE, isEditingDevice: false});
      return onSuccess(resp);
    }).catch((err) => {
      return onError(err);
    })
  }
}

export const checkPairingCode = (pairingCode,  onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {
    axios.get(`${config.endpoints.base_url}v4/devices/${pairingCode}/status`)
    .then((resp) => {
      return onSuccess(resp);
    }).catch((err) => {
      return onError(err);
    })
  }
}

export const pairDevice = (pairingCode) => {
  return (dispatch, getState) => {
    return axios.post(`${config.endpoints.base_url}v4/devices`, { "pairing_code": pairingCode });
  }
}

export const unregisterDevice = (pairingCode, onSuccess = () => {}, onError = () => {}) => {
  return (dispatch, getState) => {
    return axios.post(`${config.endpoints.base_url}v4/devices/${pairingCode}/unregister`);
  }
}

export const configAppDeviceRequest = (deviceId, usecases) => {
  return axios.post(`${config.endpoints.base_url}v4/devices/${deviceId}/config`, usecases);
}

export const installAppToADeviceRequest = (deviceId, usecase) => {
  return axios.post(`${config.endpoints.base_url}v4/devices/${deviceId}/install`, usecase);
}

export const uninstallAppToADeviceRequest = (deviceId, usecase) => {
  return axios.post(`${config.endpoints.base_url}v4/devices/${deviceId}/uninstall`, usecase);
}

export const getDeviceAppActionsByDeviceIDRequest = (deviceId, usecases) => {
  return axios(`${config.endpoints.base_url}v4/devices/${deviceId}/app-actions`);
}

export const cancelAppInstallationToADeviceRequest = (deviceId, usecase) => {
  return axios.post(`${config.endpoints.base_url}v4/devices/${deviceId}/cancel`, usecase);
}

export const addDevicesToSite = (devices = [], siteId, zoneId) => {

  const mappedDevices = devices.map((device) => {

    const deviceFeeders = device.feeders.map(feeder => {
      return {
        id: feeder.id,
        location_id: zoneId
      }
    });

    const mappedTags = device.tags.map(tag => tag.id);

    return {
      id: device.id,
      name: device.name,
      site_id: siteId,
      location_id: zoneId,
      description: device.description ? device.description : '',
      type_id: device.type_id,
      tags: mappedTags,
      feeders: deviceFeeders
    }
  });

  const mappedDevicesRequests = mappedDevices.map(device => {

    const { id, ...deviceBody } = device;

    return axios.put(
      `${config.endpoints.base_url}v2/devices/${id}`,
      deviceBody
    );
  })

  return Promise.all(mappedDevicesRequests);
}

export const deviceSendAction = (id, data, onSuccess = () => { }, onError = () => { }) => {
  return (dispatch, getState) => {
    axios.post(
      `${config.endpoints.base_url}v3/devices/${id}/send-actions`,
      data
    ).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      return onError(err);
    });
  };
};

export const getDeviceActions = (id, onSuccess = () => { }, onError = () => { }) => {
  return (dispatch, getState) => {
    axios.get(
      `${config.endpoints.base_url}v3/devices/${id}/actions?limit=20`
    ).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      return onError(err);
    });
  };
};
