
import { SETISLOADINGALERTS, SETALERTS } from '../actions/alerts';

export type alertsTypes = {
  name: string,
  description: string,
  applet: string,
  dateAdded: string
}

export type alertStateType = {
  isLoadingAlerts: boolean
  alerts: alertsTypes[]
}


const initialState: alertStateType = {
  isLoadingAlerts: false,
  alerts: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SETISLOADINGALERTS:
      return {
        ...state,
        isLoadingAlerts: action.isLoadingAlerts
      }
    case SETALERTS:
      return {
        ...state,
        alerts: action.alerts
      }
    default:
      return state;
  }
}
