import * as cityTimezones from 'city-timezones';
import { head, isUndefined } from 'lodash';
import * as config from 'src/config'
import axios from 'axios';

export const SETISLOADINGTIMEZONES = 'timezones/SETISLOADINGALERTS';
export const SETTIMEZONES = 'timezones/SETTIMEZONES';

export const getTimezonesFromCountry = (state = null, country) => {
  let tz = head(cityTimezones.findFromCityStateProvince(`${state} ${country}`))
  if (isUndefined(tz)) {
    tz = head(cityTimezones.findFromCityStateProvince(`${country}`));
  }

  return tz;
}

// export const hourInterval = ['12:00AM', '12:30AM', '01:00AM', '01:30AM', '02:00AM', '02:30AM', '03:00AM', '03:30AM', '04:00AM', '04:30AM', '05:00AM', '05:30AM', '06:00AM', '06:30AM', '07:00AM', '07:30AM', '08:00AM', '08:30AM', '09:00AM', '09:30AM', '10:00AM', '10:30AM', '11:00AM', '11:30AM', '12:00PM', '12:30PM', '01:00PM', '01:30PM', '02:00PM', '02:30PM', '03:00PM', '03:30PM', '04:00PM', '04:30PM', '05:00PM', '05:30PM', '06:00PM', '06:30PM', '07:00PM', '07:30PM', '08:00PM', '08:30PM', '09:00PM', '09:30PM', '10:00PM', '10:30PM', '11:00PM', '11:30PM']

export const hourInterval = () => {
  var result = [];
  var start = new Date(1, 1, 1, 0, 0);
  var end = new Date(1, 1, 2, 0, 0);
  for (var d = start; d < end; d.setMinutes(d.getMinutes() + 1)) {
    result.push(format(d));
  }

  let time = result.map(time => {
    return {
      label: time,
      value: time
    }
  })
  return time;
}

function format(inputDate) {
  var hours = inputDate.getHours();
  var minutes = inputDate.getMinutes();
  var ampm = hours < 12 ? "AM" : (hours = hours % 12, "PM");
  hours = hours == 0 ? 12 : hours < 10 ? ("0" + hours) : hours;
  minutes = minutes < 10 ? ("0" + minutes) : minutes;
  return hours + ":" + minutes + ampm;
}

export const getTimezones = (onSuccess, onError) => {
  return (dispatch) => {
    axios.get(`${config.endpoints.base_url}/timezones`)
      .then(res => {

        const sortedTimezonesByUTC = res.data.data.sort((a, b) => {
          // return a.utc_offset.localeCompare(b.utc_offset);
          return a.utc_offset - b.utc_offset;
        });

        // dispatch({ type: SETTIMEZONES, timezones: res.data.data });
        dispatch({ type: SETTIMEZONES, timezones: sortedTimezonesByUTC });
        onSuccess(res.data.data)
      }).catch(err => {
        onError(err);
      })
  }
};