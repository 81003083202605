import axios from 'axios';
import moment from 'moment';
import momenttz from 'moment-timezone';
import FileDownload from 'js-file-download';
import * as config from '../config';
import productsJSON from './products.json';

export const SETISLOADINGPLANOGRAMS = 'planograms/SETISLOADINGPLANOGRAMS';
export const SETPLANOGRAMS = 'planograms/SETPLANOGRAMS';

export const getPlanograms = (onSuccess = () => { }, onError = () => { }) => {
  return (dispatch, getState) => {

    dispatch({ type: SETISLOADINGPLANOGRAMS, isLoadingPlanograms: true });

    axios(`${config.endpoints.base_url}planograms`, {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      }
    }).then((resp) => {
      dispatch({ type: SETISLOADINGPLANOGRAMS, isLoadingPlanograms: false });
      dispatch({ type: SETPLANOGRAMS, planograms: resp.data.data.rows });
      // dispatch({ type: SETPLANOGRAMS, planograms: [] });
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const uploadPlanogramImage = (planogramImageFile, onUploadProgress) => {
  const formData = new FormData();

  formData.append("files", planogramImageFile);
  return axios.post(`${config.endpoints.base_url}assets`,
    formData,
    {
      onUploadProgress,
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  );
}

export const addPlanogram = ({ planogram }, onSuccess, onError) => {
  return (dispatch, getState) => {

    const retransformedPlanogram = retransformPlanogram(planogram);

    axios.post(
      `${config.endpoints.base_url}planograms`,
      retransformedPlanogram
    ).then((resp) => {
      return resp.data;
    }).then((respJson) => {
      if (respJson.status) {
        return onSuccess(respJson);
      } else {
        return onError();
      }
    }).catch((error) => {
      if (error.response) {
        return onError();
      }
    });

  };
};

export const editPlanogram = ({ id, planogram }, onSuccess = () => { }, onError = () => { }) => {
  return async (dispatch, getState) => {

    const retransformedPlanogram = retransformPlanogram(planogram);

    axios.put(
      `${config.endpoints.base_url}planograms/${id}`,
      retransformedPlanogram
    ).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const archivePlanogram = ({ id }, onSuccess = () => { }, onError = () => { }) => {
  return async (dispatch, getState) => {

    axios.delete(
      `${config.endpoints.base_url}planograms/${id}`,
      {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        }
      }
    ).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

const retransformPlanogram = (planogram) => {

  return {
    name: planogram.name,
    description: planogram.name,
    site_id: planogram.site_id,
    asset: planogram.asset
  };
};

export const getPlanogramByID = (planogramID) => {
  return (dispatch, getState) => {
    if (getState().planograms.planograms) {
      const planogram = getState().planograms.planograms.find((planogramItem) => {
        return planogramItem.id === parseInt(planogramID, 10);
      });
      if (planogram) {
        return planogram;
      }
    }
    return null;
  };
};

export const getPlanogramsBySiteID = (siteID) => {
  return (dispatch, getState) => {
    if (getState().planograms.planograms) {
      const planograms = getState().planograms.planograms.filter((planogramItem) => {
        return planogramItem.site_id === parseInt(siteID, 10);
      });
      if (planograms) {
        return planograms;
      }
    }
    return null;
  };
};

export const duplicatePlanogram = ({ id }, onSuccess = () => { }, onError = () => { }) => {
  return async (dispatch, getState) => {

    axios.post(
      `${config.endpoints.base_url}planograms/${id}/duplicate`,
      {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        }
      }
    ).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const ARTYPES = {
  FACINGS: 1,
  PRODUCTS: 2
};

export const addRegion = ({ region }, onSuccess = () => { }, onError = () => { }) => {
  return async (dispatch, getState) => {

    const retransformedRegion = retransformRegion(region);

    axios.post(
      `${config.endpoints.base_url}regions`,
      retransformedRegion
    ).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const editRegion = ({ id, region }, onSuccess = () => { }, onError = () => { }) => {
  return async (dispatch, getState) => {

    const retransformedRegion = retransformRegion(region);

    axios.put(
      `${config.endpoints.base_url}regions/${id}`,
      retransformedRegion
    ).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const removeRegion = ({ id }, onSuccess = () => { }, onError = () => { }) => {
  return async (dispatch, getState) => {

    axios.delete(
      `${config.endpoints.base_url}regions/${id}`
    ).then((resp) => {
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const addGroup = (group) => {
  const retransformedGroup = retransformGroup(group);
  return axios.post(
    `${config.endpoints.base_url}/groups`,
    retransformedGroup
  );
};

export const updateGroup = (group) => {
  const retransformedGroup = retransformGroup(group);
  return axios.put(
    `${config.endpoints.base_url}/groups/${group.id}`,
    retransformedGroup
  );
};

export const removeGroup = (group) => {
  return axios.delete(
    `${config.endpoints.base_url}/groups/${group.id}`
  );
};

export const getGlobalARs = () => {
  return axios.get(
    `${config.endpoints.base_url}/regions?is_global=true`
  );
};

export const getGroups = () => {
  return axios.get(
    `${config.endpoints.base_url}/groups`
  );
};

export const SETISLOADINGSTAFFS = 'scenes/SETISLOADINGSTAFFS';
export const SETSTAFFS = 'scenes/SETSTAFFS';

export const getStaffs = (onSuccess = () => { }, onError = () => { }) => {
  return (dispatch, getState) => {

    dispatch({ type: SETISLOADINGSTAFFS, isLoadingStaffs: true });

    axios(`${config.endpoints.base_url}staffs`).then((resp) => {
      dispatch({ type: SETISLOADINGSTAFFS, isLoadingStaffs: false });
      dispatch({ type: SETSTAFFS, staffs: resp.data.data.rows });
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

export const savePlanogramRequest = (planogram) => {
  const retransformedPlanogramWithRegions = retransformPlanogramWithRegions(planogram);
  return axios.put(
    `${config.endpoints.base_url}/planograms/${planogram.id}`,
    retransformedPlanogramWithRegions
  );
};

const retransformRegion = (region) => {

  return {
    name: region.name,
    description: region.name,
    scene_id: region.scene_id,
    features: region.features,
    points: region.points,
    color_hm: '',
    contact_threshold: region.contact_threshold,
    proximity_threshold: region.proximity_threshold,
    cleanuptime_threshold: region.cleanuptime_threshold,
    cleanuptime_unit_threshold: 'min',
    assigned_to: 2,
    preset_id: null,
    sla_time: region.sla_time,
    sla_time_unit: 'min',
  };
};

const retransformGroup = (group) => {
  return {
    name: group.name
  }
};

const retransformPlanogramWithRegions = (planogram) => {
  return {
    ...planogram
  }
};

export const getProducts = () => {
  return productsJSON;
};