
import { SETISLOADINGTIMEZONES, SETTIMEZONES } from '../actions/timezones';

export type timezonesTypes = {
}

export type timezoneStateType = {
    isLoadingTimezones: boolean
    timezones: any[]
}


const initialState: timezoneStateType = {
    isLoadingTimezones: false,
    timezones: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SETISLOADINGTIMEZONES:
            return {
                ...state,
                isLoadingTimezones: action.isLoadingTimezones
            }
        case SETTIMEZONES:
            return {
                ...state,
                timezones: action.timezones
            }
        default:
            return state;
    }
}
