
import { SET_SHOWSESSIONGENERATEVIDEONOTICE } from '../actions/settingsPersist';

const initialState = {
  showSessionsGenerateVideoNotice: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOWSESSIONGENERATEVIDEONOTICE: {
      return { ...state, showSessionsGenerateVideoNotice: action.data };
    }
    default:
      return state;
  }
};
